import React, { useState, useEffect } from "react";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import * as auth from "../../../services/AuthService";
import { Tooltip } from "react-tooltip";

const AdminMenus = ({ user }) => {
  let navigate = useNavigate();
  const location = useLocation();

  // logout function
  const handleLogout = async () => {
    try {
      // Call function and API endpoint
      let res = await auth.logout({ user_id: user.id, token: user.token }, "admin/logout");
      if (res) {
        setTimeout(() => {
          navigate("/admin/login");
        }, 500);
      }
    } catch (error) {
      console.error("Unexpected error structure:", error);
    }
  };

  const getClassNames = (path) => {
    return location.pathname === path ? "menu-item menuitem-active" : "menu-item";
  };

  const [sidebarSize, setSidebarSize] = useState(localStorage.getItem("sidebarSize") || "default");

  useEffect(() => {
    const handleStorageChange = () => {
      setSidebarSize(localStorage.getItem("sidebarSize"));
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  return (
    <ul className="menu">
      <li className="menu-title">Navigation</li>
      <li className={getClassNames("/admin/dashboard")}>
        <NavLink to="/admin/dashboard" className="menu-link" activeClassName="active" place="right">
          <span className="menu-icon">
            <i className="fas fa-home" />
          </span>
          <span className="menu-text"> Dashboard </span>
        </NavLink>
      </li>

      {/* 
      {...(sidebarSize === "condensed"
          ? {
              "data-tooltip-id": "mt-dashboard",
              "data-tooltip-content": "Dashboard",
              "data-tooltip-place": "right",
              "data-tooltip-variant": "light",
              "data-tooltip-position-strategy": "fixed",
            }
          : {})}
      {sidebarSize === "condensed" && <Tooltip id={`mt-dashboard`}></Tooltip>}
      */}

      <li className={getClassNames("/admin/calender")}>
        <NavLink to="/admin/calender" className="menu-link" activeClassName="active">
          <span className="menu-icon">
            <i className="fas fa-calendar-alt" />
          </span>
          <span className="menu-text"> Calendar </span>
        </NavLink>
      </li>
      <li className="menu-title">Jobs</li>
      <li className={getClassNames("/admin/job-postings")}>
        <NavLink to="/admin/job-postings" className="menu-link" activeClassName="active">
          <span className="menu-icon">
            <i className="fas fa-briefcase" />
          </span>
          <span className="menu-text"> Job Postings </span>
        </NavLink>
      </li>
      <li className={getClassNames("/admin/search")}>
        <NavLink to="/admin/search" className="menu-link" activeClassName="active">
          <span className="menu-icon">
            <i className="fas fa-search" />
          </span>
          <span className="menu-text"> Search Resume</span>
        </NavLink>
      </li>
      <li className={getClassNames("/admin/job-applications")}>
        <NavLink to="/admin/job-applications" className="menu-link" activeClassName="active">
          <span className="menu-icon">
            <i className="fas fa-address-card" />
          </span>
          <span className="menu-text"> Job Applications</span>
        </NavLink>
      </li>

      <li className="menu-title">HR Management</li>

      <li className={getClassNames("/admin/follow-ups")}>
        <NavLink to="/admin/follow-ups" className="menu-link" activeClassName="active">
          <span className="menu-icon">
            <i className="fas fa-podcast" />
          </span>
          <span className="menu-text"> Follow Ups </span>
        </NavLink>
      </li>

      <li className={getClassNames("/admin/applicants")}>
        <NavLink to="/admin/applicants" className="menu-link" activeClassName="active">
          <span className="menu-icon">
            <i className="fas fa-users" />
          </span>
          <span className="menu-text"> Applicants </span>
        </NavLink>
      </li>

      <li className={getClassNames("/admin/candidates")}>
        <NavLink to="/admin/candidates" className="menu-link" activeClassName="active">
          <span className="menu-icon">
            <i className="fas fa-users" />
          </span>
          <span className="menu-text"> Candidates </span>
        </NavLink>
      </li>

      <li className={getClassNames("/admin/employees")}>
        <NavLink to="/admin/employees" className="menu-link" activeClassName="active">
          <span className="menu-icon">
            <i className="fas fa-users" />
          </span>
          <span className="menu-text"> Employees </span>
        </NavLink>
      </li>

      <li className={getClassNames("/admin/agencies")}>
        <NavLink to="/admin/agencies" className="menu-link" activeClassName="active">
          <span className="menu-icon">
            <i className="fas fa-users" />
          </span>
          <span className="menu-text"> Agencies </span>
        </NavLink>
      </li>

      <li className={getClassNames("/admin/facilities")}>
        <NavLink to="/admin/facilities" className="menu-link" activeClassName="active">
          <span className="menu-icon">
            <i className="fas fa-users" />
          </span>
          <span className="menu-text"> Facilities </span>
        </NavLink>
      </li>

      <li className={getClassNames("/admin/office-admins")}>
        <NavLink to="/admin/office-admins" className="menu-link" activeClassName="active">
          <span className="menu-icon">
            <i className="fas fa-users" />
          </span>
          <span className="menu-text"> Office Admins </span>
        </NavLink>
      </li>

      <li className="menu-title">Compliance Files</li>

      <li className={getClassNames("/admin/compliance-files")}>
        <NavLink to="/admin/compliance-files" className="menu-link" activeClassName="active">
          <span className="menu-icon">
            <i className="fas fa-folder" />
          </span>
          <span className="menu-text"> Manage </span>
        </NavLink>
      </li>

      <li className={getClassNames("/admin/compliance-file-categories")}>
        <NavLink
          to="/admin/compliance-file-categories"
          className="menu-link"
          activeClassName="active"
        >
          <span className="menu-icon">
            <i className="fas fa-share-alt" />
          </span>
          <span className="menu-text"> Categories </span>
        </NavLink>
      </li>

      <li className={getClassNames("/admin/compliance-file-types")}>
        <NavLink to="/admin/compliance-file-types" className="menu-link" activeClassName="active">
          <span className="menu-icon">
            <i className="fas fa-bezier-curve" />
          </span>
          <span className="menu-text"> Types </span>
        </NavLink>
      </li>

      {/* 
      <li className={getClassNames("/admin/compliance-list")}>
        <NavLink to="/admin/compliance-list" className="menu-link" activeClassName="active">
          <span className="menu-icon">
            <i className="fas fa-list" />
          </span>
          <span className="menu-text"> Facility Compliance Lists </span>
        </Link>
      </li>
      */}

      <li className={getClassNames("/admin/documents")}>
        <NavLink to="/admin/documents" className="menu-link" activeClassName="active">
          <span className="menu-icon">
            <i className="fas fa-folder-open" />
          </span>
          <span className="menu-text"> Documents </span>
        </NavLink>
      </li>

      <li className={getClassNames("/admin/compliance-checklists")}>
        <NavLink to="/admin/compliance-checklists" className="menu-link" activeClassName="active">
          <span className="menu-icon">
            <i className="fas fa-edit" />
          </span>
          <span className="menu-text"> Checklists </span>
        </NavLink>
      </li>

      <li className="menu-title">Tasks</li>
      <li className={getClassNames("/admin/tasks")}>
        <NavLink to="/admin/tasks" className="menu-link" activeClassName="active">
          <span className="menu-icon">
            <i className="fas fa-tasks" />
          </span>
          <span className="menu-text"> Tasks </span>
        </NavLink>
      </li>
      <li className="menu-title">Admin</li>
      {/* 
      <li className={getClassNames("/admin/company-profile")}>
        <NavLink to={"/admin/company-profile"} className="menu-link" activeClassName="active">
          <span className="menu-icon">
            <i className="fas fa-briefcase" />
          </span>
          <span className="menu-text"> Company Profile </span>
        </Link>
      </li>
      */}
      <li className={getClassNames("/admin/sub-admins")}>
        <NavLink to="/admin/sub-admins" className="menu-link" activeClassName="active">
          <span className="menu-icon">
            <i className="fas fa-users-cog" />
          </span>
          <span className="menu-text"> Sub Admins </span>
        </NavLink>
      </li>
      <li className={getClassNames("/admin/professions")}>
        <NavLink to="/admin/professions" className="menu-link" activeClassName="active">
          <span className="menu-icon">
            <i className="fas fa-globe"></i>
          </span>
          <span className="menu-text"> Professions </span>
        </NavLink>
      </li>
      <li className={getClassNames("/admin/specialities")}>
        <NavLink to="/admin/specialities" className="menu-link" activeClassName="active">
          <span className="menu-icon">
            <i className="fas fa-user-md"></i>
          </span>
          <span className="menu-text"> Specialties </span>
        </NavLink>
      </li>
      <li className={getClassNames("/admin/rec-comp-teams")}>
        <NavLink to="/admin/rec-comp-teams" className="menu-link" activeClassName="active">
          <span className="menu-icon">
            <i className="fas fa-users"></i>
          </span>
          <span className="menu-text"> Rec/Compliance Teams </span>
        </NavLink>
      </li>
      <li className={getClassNames("/admin/reports-analytics")}>
        <NavLink to="/admin/reports-analytics" className="menu-link" activeClassName="active">
          {" "}
          {/* to="/admin/reports-analytics" */}
          <span className="menu-icon">
            <i className="fas fa-chart-line"></i>
          </span>
          <span className="menu-text"> Reports &amp; Analytics </span>
        </NavLink>
      </li>
      <li className={getClassNames("/admin/announcements")}>
        <NavLink to="/admin/announcements" className="menu-link" activeClassName="active">
          <span className="menu-icon">
            <i className="fas fa-bullhorn"></i>
          </span>
          <span className="menu-text"> Announcements </span>
        </NavLink>
      </li>
      <li className={getClassNames("/admin/skills")}>
        <NavLink to="/admin/skills" className="menu-link" activeClassName="active">
          <span className="menu-icon">
            <i className="fas fa-cog"></i>
          </span>
          <span className="menu-text"> Skills </span>
        </NavLink>
      </li>

      <li className="menu-title">Scheduling</li>
      <li className={getClassNames("/admin/scheduling")}>
        <NavLink to="/admin/scheduling" className="menu-link ">
          <span className="menu-icon">
            <i className="fas fa-calendar-alt" />
          </span>
          <span className="menu-text"> Scheduling </span>
        </NavLink>
      </li>
      <li className="menu-title">Account Management</li>
      <li className={getClassNames("/admin/clients")}>
        <NavLink to="/admin/clients" className="menu-link" activeClassName="active">
          <span className="menu-icon">
            <i className="fas fa-users" />
          </span>
          <span className="menu-text"> Clients </span>
        </NavLink>
      </li>
      <li className={getClassNames("/admin/assignments")}>
        <NavLink to="/admin/assignments" className="menu-link" activeClassName="active">
          <span className="menu-icon">
            <i className="fas fa-edit" />
          </span>
          <span className="menu-text"> Assignments </span>
        </NavLink>
      </li>
      <li className={getClassNames("/admin/submissions")}>
        <NavLink to="/admin/submissions" className="menu-link" activeClassName="active">
          <span className="menu-icon">
            <i className="fas fa-paper-plane" />
          </span>
          <span className="menu-text"> Submissions </span>
        </NavLink>
      </li>
      <li className={getClassNames("/admin/redirects")}>
        <NavLink to="/admin/redirects" className="menu-link" activeClassName="active">
          <span className="menu-icon">
            <i className="fas fa-external-link-alt" />
          </span>
          <span className="menu-text"> Redirects </span>
        </NavLink>
      </li>

      <li className="menu-title mt-2">Others</li>

      <li className={getClassNames("/admin/blogs")}>
        <NavLink to="/admin/blogs" className="menu-link" activeClassName="active">
          <span className="menu-icon">
            <i className="fas fa-blog" />
          </span>
          <span className="menu-text"> Blogs </span>
        </NavLink>
      </li>

      <li className={getClassNames("/admin/sms") || getClassNames("/admin/email")}>
        <a href="#messages" data-bs-toggle="collapse" className="menu-link  ">
          <span className="menu-icon">
            <i className="fas fa-comments"></i>
          </span>
          <span className="menu-text"> Messages </span>
          <span className="menu-arrow" />
        </a>
        <div className="collapse" id="messages">
          <ul className="sub-menu">
            {/* 
            <li className={getClassNames("/admin/sms")}>
              <NavLink to="/admin/sms" className="menu-link ">
                {" "}
                <span className="menu-text"> SMS </span>
              </NavLink>
            </li>
            */}
            <li className={getClassNames("/admin/email")}>
              <NavLink to="/admin/email" className="menu-link ">
                {/* to="/admin/mails" */}
                <span className="menu-text"> Email </span>
              </NavLink>
            </li>
          </ul>
        </div>
      </li>

      <li className={getClassNames("/admin/activity-log")}>
        <NavLink to="/admin/activity-log" className="menu-link" activeClassName="active">
          <span className="menu-icon">
            <i className="fas fa-history" />
          </span>
          <span className="menu-text"> Activity Logs </span>
        </NavLink>
      </li>

      <li className={getClassNames("/admin/contact-enquiries")}>
        <NavLink to="/admin/contact-enquiries" className="menu-link" activeClassName="active">
          <span className="menu-icon">
            <i className="fas fa-address-book" />
          </span>
          <span className="menu-text"> Contact Enquiries </span>
        </NavLink>
      </li>

      <li className={getClassNames("/admin/pilot-partner-applications")}>
        <NavLink
          to="/admin/pilot-partner-applications"
          className="menu-link"
          activeClassName="active"
        >
          <span className="menu-icon">
            <i className="fas fa-address-book" />
          </span>
          <span className="menu-text"> Pilot Partner Applications </span>
        </NavLink>
      </li>

      {/* 
      <li className={getClassNames("/admin/calender")}>
        <NavLink to="/admin/subscriptions" className="menu-link text-muted">
          <span className="menu-icon">
            <i className="fas fa-box-open"></i>
          </span>
          <span className="menu-text"> Subscription Plans </span>
        </NavLink>
      </li>
      <li className={getClassNames("/admin/calender")}>
        <NavLink to="/admin/transactions" className="menu-link text-muted">
          <span className="menu-icon">
            <i className="fas fa-dollar-sign"></i>
          </span>
          <span className="menu-text"> Transactions </span>
        </NavLink>
      </li>
        */}

      <li className="menu-item">
        <NavLink
          to="#"
          onClick={() => handleLogout()}
          className="menu-link"
          activeClassName="active"
        >
          <span className="menu-icon">
            <i className="fas fa-sign-out-alt" />
          </span>
          <span className="menu-text"> Logout </span>
        </NavLink>
      </li>
    </ul>
  );
};

export default AdminMenus;
