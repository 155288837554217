import React, { useEffect, useState } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import Header from "../../Layout/Header";
import Footer from "../../Layout/Footer";
import Sidebar from "../../Layout/Sidebar";
import ThemeSettings from "../../Layout/ThemeSettings";
import * as api from "../../../services/ApiService";
import * as tokenUtils from "../../../utils/tokenUtils";
import { Tooltip } from "react-tooltip";
import Spinner from "../../Loader/Spinner";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { formatAmount } from "../../../utils/AmountFormatter";

function JobDetail() {
  useEffect(() => {
    // Scroll to the top of the page when component mounts
    window.scrollTo(0, 0);
  }, []);
  
  let navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { jobId } = useParams();

  // Get data from token util
  let user = tokenUtils.getTokenData();

  const [isBookmarkedJob, setIsBookmarkedJob] = useState(false);

  const [jobRow, setJobRow] = useState({});

  const toggleBookmarkJob = async () => {
    try {
      setIsLoading(true);
      const param = {
        user_id: user.id,
        token: user.token,
        job_id: jobRow.id,
      };
      const apiData = await api.postMethod(param, "user/update-job-bookmark");
      if (apiData.status) {
        setIsBookmarkedJob(false);
        if (apiData.data == 1) setIsBookmarkedJob(true);
        setIsLoading(false);
        toast.success(apiData.message);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  };

  const [isAppliedJob, setIsAppliedJob] = useState(false);

  const toggleAppliedJob = async () => {
    try {
      setIsLoading(true);
      const param = {
        user_id: user.id,
        user_role_id: user.role_id,
        token: user.token,
        job_id: jobRow.id,
      };
      const apiData = await api.postMethod(param, "user/apply-job");
      if (apiData.status) {
        setIsAppliedJob(false);
        if (apiData.data == 1) setIsAppliedJob(true);
        setIsLoading(false);
        toast.success(apiData.message);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  };

  const [attachments, setAttatchments] = useState({});
  const [loading, setLoading] = useState(true);
  const [noRecordsFound, setNoRecordsFound] = useState(false);

  /* Job Message Submission */
  const [formData, setformData] = useState({
    id: null,
    user_id: user.id,
    token: user.token,
    job_id: null,
    message: null,
  });

  const fetchJobData = async () => {
    try {
      const param = {
        user_id: user.id,
        token: user.token,
        jobId: jobId,
      };
      const apiData = await api.postMethod(param, "user/get-job-detail");

      if (apiData.status) {
        setJobRow(apiData.data);

        setformData({
          ...formData,
          job_id: apiData.data.id,
        });

        setIsBookmarkedJob(false);
        if (apiData.data.bookmark_exists == 1) setIsBookmarkedJob(true);

        setIsAppliedJob(false);
        if (apiData.data.job_applied == 1) setIsAppliedJob(true);

        setAttatchments(apiData.data.attachments);

        setLoading(false);
        // Check if data array is empty
        if (apiData.data.attachments.length === 0) {
          setNoRecordsFound(true);
        }
      } else {
        navigate("/user/jobs");
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchJobData();
  }, []); // Empty dependency array ensures that the effect runs only once, when the component mounts

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = {
      day: "2-digit",
      month: "short",
      year: "numeric",
    };
    const formattedDate = date.toLocaleDateString("en-US", options);
    return `${formattedDate}`;
  };

  const formatTime = (dateString) => {
    const date = new Date(dateString);
    const formattedTime = date.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
    return `${formattedTime}`;
  };

  //Define the validation schema
  const validationSchema = Yup.object({
    message: Yup.string().required("Message is Required"),
  });

  const [errors, setErrors] = useState({});

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await validationSchema.validate(formData, { abortEarly: false });
      const result = await api.postMethod(formData, "user/insert-job-message");
      if (result.status) {
        setformData({
          ...formData,
          message: null,
        });
        document.getElementById("message").value = "";
        setIsLoading(false);
        toast.success("Message has been sent!");
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      const newErrors = {};
      if (error.inner && Array.isArray(error.inner)) {
        error.inner.forEach((element) => {
          newErrors[element.path] = element.message;
        });
      } else {
        console.error("Unexpected error structure:", error);
      }
      setErrors(newErrors);
      setIsLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setformData({ ...formData, [name]: value });

    validateFields(name, value);
  };

  const validateFields = (name, value) => {
    try {
      validationSchema.validateSyncAt(name, { [name]: value });

      // Clear the error for the current field if validation passes
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    } catch (error) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: error.message,
      }));
    }
  };

  const messagePreviewStyle = {
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "normal",
  };

  const stickySidebarStyle = {
    position: "-webkit-sticky" /* For Safari */,
    position: "sticky",
    top: "100px" /* Adjust this value as needed */,
  };

  return (
    <>
      <div id="wrapper">
        <Sidebar />

        <div className="content-page">
          <Header />

          <div className="content">
            {/* Start Content*/}
            <div className="container-fluid">
              {/* start page title */}
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box">
                    <div className="page-title-right">
                      <ol className="breadcrumb m-0">
                        <li className="breadcrumb-item">
                          <Link to="/dashboard">Home</Link>
                        </li>
                        <li className="breadcrumb-item">
                          <Link to="/user/jobs">Jobs</Link>
                        </li>
                        <li className="breadcrumb-item active">Job Detail (#{jobRow.unique_id})</li>
                      </ol>
                    </div>
                    <h4 className="page-title">Job Detail</h4>
                  </div>
                </div>
              </div>
              {/* end page title */}
              <div className="row">
                <div className="col-xl-8 col-lg-6">
                  {/* project card */}
                  <div className="card d-block custom-box-shadow">
                    <div className="card-body">
                      <p className="text-muted mb-0">
                        {jobRow.main_company_name || jobRow.admin_app_name}
                      </p>
                      <h3 className="mt-0 font-20 ">{jobRow.title}</h3>

                      {jobRow.employment_type_title && (
                        <>
                          <div className="badge bg-info text-light mb-3 me-1">
                            {jobRow.employment_type_title}
                          </div>
                        </>
                      )}

                      {jobRow.is_urgent == 1 && (
                        <div className="badge bg-warning text-light mb-3 me-1">Urgent</div>
                      )}

                      {jobRow.salary_start_range && jobRow.salary_end_range && (
                        <>
                          <div className="badge bg-warning text-light mb-3 me-1 float-end">
                            Salary :{" "}
                            {jobRow.show_pay_rate == 0 ? (
                              <>Contact Us</>
                            ) : jobRow.salary_start_range ? (
                              <>
                                ${formatAmount(jobRow.salary_start_range)} {jobRow.salary_type}
                              </>
                            ) : (
                              "N/A"
                            )}
                          </div>
                        </>
                      )}

                      <div className="p-2 border text-center mb-3">
                        <div className="row">
                          <div className="col-md-4">
                            <div className="mb-2">
                              <h5>Job ID</h5>
                              <p>#{jobRow.unique_id}</p>
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="mb-2">
                              <h5>Location</h5>
                              <p>
                                {jobRow.state_code !== null && jobRow.city_name !== null
                                  ? jobRow.city_name + ", " + jobRow.state_code
                                  : jobRow.state_code !== null
                                  ? jobRow.state_code
                                  : jobRow.city_name !== null
                                  ? jobRow.city_name
                                  : "-"}
                              </p>
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="mb-2">
                              <h5>Specialty</h5>
                              <p>{jobRow.specialty !== null ? jobRow.specialty : "-"}</p>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="mb-2">
                              <h5>Shifts</h5>
                              <p>{jobRow.shift_title ? jobRow.shift_title : "-"}</p>
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="mb-2">
                              <h5>Date Posted</h5>
                              <p>
                                {formatDate(jobRow.created_at)}{" "}
                                <small className="text-muted">
                                  {formatTime(jobRow.created_at)}
                                </small>
                              </p>
                            </div>
                          </div>

                          {/* 
                          <div className="col-md-4">
                            <div className="mb-2">
                              <h5>Expiration date</h5>
                              <p>
                                {jobRow.end_date != null
                                  ? `${formatDate(jobRow.end_date)} ${formatTime(jobRow.end_date)}`
                                  : "-"}
                              </p>
                            </div>
                          </div>
                          */}

                          {/* 
                          <div className="col-md-4">
                            <div className="mb-2">
                              <h5>Salary Range</h5>
                              <p>
                                ${jobRow.salary_start_range ? jobRow.salary_start_range : "0"} - $
                                {jobRow.salary_end_range ? jobRow.salary_end_range : "0"}
                              </p>
                            </div>
                          </div>
                          */}

                          <div className="col-md-4">
                            <div className="mb-2">
                              <h5>Total Opening</h5>
                              <p>{jobRow.total_opening}</p>
                            </div>
                          </div>
                          {/* 
                          <div className="col-md-4">
                            <div className="mb-2">
                              <h5>Type</h5>
                              <p>
                                {jobRow.employment_type_title ? jobRow.employment_type_title : "-"}
                              </p>
                            </div>
                          </div>
                          */}
                        </div>
                      </div>
                      {jobRow.description != null ? (
                        <>
                          <h5>Job Description</h5>
                          <p className="text-muted mb-2">{jobRow.description}</p>
                        </>
                      ) : (
                        ""
                      )}
                      {jobRow.qualification != null ? (
                        <>
                          <h5>Job Qualification</h5>
                          <p className="text-muted mb-2">{jobRow.qualification}</p>
                        </>
                      ) : (
                        ""
                      )}
                      {jobRow.responsibilities != null ? (
                        <>
                          <h5>Job Responsibilities</h5>
                          <p className="text-muted mb-2">{jobRow.responsibilities}</p>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                    {/* end card-body*/}
                  </div>
                  {/* end card*/}
                </div>
                <div className="col-lg-6 col-xl-4 mb-3">
                  <div className="sticky-sidebar" style={stickySidebarStyle}>
                    <div className="d-flex align-items-center mb-3">
                      <div className="w-100 me-2">
                        <button
                          type="button"
                          className={
                            isAppliedJob
                              ? "btn  btn-primary btn-block w-100"
                              : "btn  btn-secondary btn-block w-100"
                          }
                          onClick={toggleAppliedJob}
                        >
                          {isAppliedJob
                            ? jobRow.job_application_status == 1
                              ? "Applied"
                              : jobRow.job_application_status == 2
                              ? "Shortlisted"
                              : jobRow.job_application_status == 3
                              ? "Submitted"
                              : jobRow.job_application_status == 4
                              ? "Interviewing"
                              : jobRow.job_application_status == 5
                              ? "Offered"
                              : jobRow.job_application_status == 6
                              ? "Hired"
                              : "Applied for Job"
                            : "Apply for Job"}
                        </button>
                      </div>
                      <div className="ms-auto">
                        <button
                          type="button"
                          className={isBookmarkedJob ? "btn btn-info" : "btn btn-secondary"}
                          data-tooltip-id="mt-1"
                          data-tooltip-content={isBookmarkedJob ? "Saved Job" : "Mark as Saved Job"}
                          onClick={toggleBookmarkJob}
                        >
                          <i className="far fa-bookmark" />
                        </button>
                        <Tooltip id="mt-1"></Tooltip>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header border-bottom">
                        <h5 className="card-title mb-0 fw-semibold">Messages</h5>
                      </div>
                      <div className="card-body">
                        <form onSubmit={handleSubmit}>
                          <textarea
                            id="message"
                            className="form-control"
                            rows={5}
                            name="message"
                            onChange={handleChange}
                            defaultValue={formData.message}
                          />
                          {errors.message && <span className="error">{errors.message}</span>}
                          <button type="submit" className="btn btn-primary w-100 mt-3">
                            Send Message
                          </button>
                        </form>
                      </div>
                    </div>

                    {jobRow.company_id ? (
                      <div className="card">
                        <div className="card-header border-bottom">
                          <h5 className="card-title mb-0 fw-semibold">About Company</h5>
                        </div>
                        <div className="card-body">
                          <div className="media mb-3">
                            {jobRow.company_profile_pic_path && (
                              <img
                                className="d-flex mr-3 rounded-circle avatar-md float-start me-2"
                                src={jobRow.company_profile_pic_path}
                                alt={jobRow.company_name}
                              />
                            )}
                            <div className="media-body">
                              <h4 className="mt-0 mb-1">{jobRow.company_name}</h4>
                              <p className="text-muted">
                                <strong>Member Since</strong> :{" "}
                                {formatDate(jobRow.company_created_at)}
                              </p>
                            </div>
                          </div>

                          {jobRow.bio && (
                            <div className="mb-0">
                              <h5>About {jobRow.company_name}</h5>
                              <p style={messagePreviewStyle}>{jobRow.bio}</p>
                            </div>
                          )}

                          {jobRow.founded_in && (
                            <div className="mb-0">
                              <h5>Founded In</h5>
                              <p>{jobRow.founded_in}</p>
                            </div>
                          )}

                          {jobRow.primary_industry && (
                            <div className="mb-0">
                              <h5>Primary Industry</h5>
                              <p>{jobRow.primary_industry}</p>
                            </div>
                          )}

                          {jobRow.company_size && (
                            <div className="mb-0">
                              <h5>Company Size</h5>
                              <p>{jobRow.company_size}</p>
                            </div>
                          )}

                          {jobRow.company_state_code && jobRow.company_city_name && (
                            <div className="mb-0">
                              <h5>Location</h5>
                              <p className="mb-0">
                                {jobRow.company_state_code !== null &&
                                jobRow.company_city_name !== null
                                  ? jobRow.company_city_name + ", " + jobRow.company_state_code
                                  : jobRow.company_state_code !== null
                                  ? jobRow.company_state_code
                                  : jobRow.company_city_name !== null
                                  ? jobRow.company_city_name
                                  : ""}
                              </p>
                            </div>
                          )}
                        </div>
                        <div className="card-footer text-center border-top">
                          <Link
                            to={"/user/company-profile/" + jobRow.company_unique_id}
                            className="btn btn-primary"
                          >
                            View Full Details
                          </Link>
                        </div>
                      </div>
                    ) : (
                      <div className="card">
                        <div className="card-body">
                          <div className="media mb-3">
                            {jobRow.company_profile_pic_path && (
                              <img
                                className="d-flex mr-3 rounded-circle avatar-md float-start me-2"
                                src={jobRow.company_profile_pic_path}
                                alt={jobRow.company_name}
                              />
                            )}
                            <div className="media-body">
                              <h4 className="mt-0 mb-1">{jobRow.admin_app_name}</h4>
                              {/* 
                              <p className="text-muted">
                                <strong>Member Since</strong> :{" "}
                                {formatDate(jobRow.company_created_at)}
                              </p>
                              */}
                            </div>
                          </div>

                          <div className="clearfix"></div>

                          {jobRow.admin_company_bio && (
                            <div className="mb-0">
                              <h5>About {jobRow.admin_app_name}</h5>
                              <p style={messagePreviewStyle}>{jobRow.admin_company_bio}</p>
                            </div>
                          )}

                          {jobRow.admin_company_email && (
                            <div className="mb-0">
                              <h5>Company Email</h5>
                              <p>{jobRow.admin_company_email}</p>
                            </div>
                          )}

                          {jobRow.admin_company_phone && (
                            <div className="mb-0">
                              <h5>Company Phone</h5>
                              <p>{jobRow.admin_company_phone}</p>
                            </div>
                          )}

                          {jobRow.admin_company_website && (
                            <div className="mb-0">
                              <h5>Company Website</h5>
                              <p>{jobRow.admin_company_website}</p>
                            </div>
                          )}

                          {jobRow.admin_company_address && (
                            <div className="mb-0">
                              <h5>Company Address</h5>
                              <p>{jobRow.admin_company_address}</p>
                            </div>
                          )}
                        </div>
                      </div>
                    )}

                    {!noRecordsFound && (
                      <div className="card">
                        <div className="card-body">
                          <h5 className="card-title mb-3">Attatchments</h5>
                          {Object.entries(attachments).map(([key, value]) => (
                            <div className="card mb-0 mt-2 shadow-none border" key={value.id}>
                              <div className="p-2">
                                <div className="row align-items-center">
                                  <div className="col-auto">
                                    <div className="avatar-sm">
                                      <span className="avatar-title badge-soft-primary text-primary rounded">
                                        {value.file_type}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="col ps-0">
                                    <span className="text-muted fw-bold">{value.title}</span>
                                    <p className="mb-0 font-12">{value.file_size}</p>
                                  </div>
                                  <div className="col-auto">
                                    <a
                                      href={value.dir_path}
                                      download
                                      target="_blank"
                                      rel="noreferrer"
                                      className="btn btn-link font-16 text-muted"
                                    >
                                      <i className="dripicons-download" />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                {/* end col */}
              </div>
              {/* end row */}
            </div>
            {/* container */}
          </div>
          {/* content */}

          <Footer />
        </div>
      </div>

      <ThemeSettings />
      {isLoading === true && <Spinner />}
    </>
  );
}

export default JobDetail;
