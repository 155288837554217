import { Tooltip } from "react-tooltip";
import useAnnouncement from "../../hooks/useAnnouncement";

const Announcement = ({module}) => {
    const {announcement, showAnnouncement, handleDismiss, handleShow} = useAnnouncement(module);
    return (
        <div className="d-inline-block w-100">
            {announcement && showAnnouncement && (
                <div
                    className="alert alert-info mt-3 mb-0 alert-dismissible fade show"
                    role="alert"
                >
                    <div dangerouslySetInnerHTML={{ __html: announcement }} />
                    <button type="button" className="btn-close" onClick={handleDismiss}></button>
                </div>
            )}
            {!showAnnouncement && (
                <>
                    <button
                        className="btn btn-info mt-3 float-end btn-rounded"
                        onClick={handleShow}
                        data-tooltip-id="mt-33"
                        data-tooltip-content="Show Announcement"
                    >
                        <i class="fas fa-info"></i>
                    </button>
                    <Tooltip id="mt-33"></Tooltip>
                </>
            )}
        </div>
    )
}

export default Announcement;