import React, { useState, useEffect } from "react";
import Header from "../../Layout/Header";
import Footer from "../../Layout/Footer";
import Sidebar from "../../Layout/Sidebar";
import ThemeSettings from "../../Layout/ThemeSettings";
import { Link } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import Modal from "react-bootstrap/Modal";
import * as api from "../../../services/ApiService";
import * as Yup from "yup";
import * as document from "../../../services/DocumentService";
import * as tokenUtils from "../../../utils/tokenUtils";
import PageTitle from "../../Layout/PageTitle";
import { currentRole } from "../../Layout/HelmetComponent";
import Spinner from "../../Loader/Spinner";
import { toast } from "react-toastify";
import { formatDate } from "../../../utils/dateFormat";

function Search() {
  // Get data from token util
  let user = tokenUtils.getTokenData();
  const [isLoading, setIsLoading] = useState(false);
  const [specilityLists, setspecilityLists] = useState([]);
  const fetchSpecilities = async () => {
    try {
      const param = {
        user_id: user.id,
        token: user.token,
      };
      const apiData = await api.postMethod(param, "client/get-specialities");

      setspecilityLists(apiData.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchSpecilities();
  }, []); // Empty dependency array ensures that the effect runs only once, when the component mounts

  const [states, setStates] = useState([]);

  useEffect(() => {
    // Function to fetch data from the API
    const fetchStates = async () => {
      try {
        const apiData = await api.getMethod("get-states");
        setStates(apiData.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Call the fetchData function when the component mounts
    fetchStates();
  }, []); // Empty dependency array ensures that the effect runs only once, when the component mounts

  /* Filters function Start */
  const [showFilterDropdown, setFilterDropdown] = useState(true);
  const handleToggleFilterDropDown = () => {
    setFilterDropdown(!showFilterDropdown);
  };

  // State for filter values
  const [filters, setFilters] = useState({
    keyword: "",
    state_id: "",
    speciality_id: "",
    start_date: "",
    end_date: "",
  });

  // Handle form reset
  const handleReset = () => {
    const defaultFilters = {
      keyword: "",
      state_id: "",
      speciality_id: "",
      start_date: "",
      end_date: "",
    };
    setHideUserCard(true);
    setFilters(defaultFilters);
    setApplicants({});
    setFilterDropdown(false);
  };

  // Handle form input change
  const handleFilterInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      setFilters({
        ...filters,
        [name]: checked,
      });
    } else {
      setFilters({
        ...filters,
        [name]: value,
      });
    }
  };

  // Handle form submit
  const handleFilterSubmit = (e) => {
    e.preventDefault();
    fetchApplicants();
  };
  /* Filters function End */
  const [hideUserCard, setHideUserCard] = useState(true);

  const [applicants, setApplicants] = useState({});
  const [loading, setLoading] = useState(true);
  const [noRecordsFound, setNoRecordsFound] = useState(false);

  const fetchApplicants = async () => {
    setLoading(true);
    setHideUserCard(false);
    try {
      const param = {
        user_id: user.id,
        token: user.token,
      };
      const queryParams = { ...param, ...filters };
      const apiData = await api.postMethod(queryParams, "client/get-all-users");
      //const apiDataObj = Object.setPrototypeOf(apiData, Object.prototype);

      setApplicants(apiData.data);
      setLoading(false);
      setNoRecordsFound(false);
      setFilterDropdown(true);
      // Check if data array is empty
      if (apiData.data.length === 0) {
        setNoRecordsFound(true);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  /*
  useEffect(() => {
    fetchApplicants();
  }, []); // Empty dependency array ensures that the effect runs only once, when the component mounts
  */

  const breadcrumbItems = [
    { text: currentRole + " Dashboard", link: "/client/dashboard" },
    { text: "Search", link: null },
  ];

  return (
    <>
      <div id="wrapper">
        <Sidebar />

        <div className="content-page">
          <Header />

          <div className="content">
            {/* Start Content*/}
            <div className="container-fluid">
              {/* start page title */}
              <PageTitle title="Search" breadcrumbItems={breadcrumbItems} />

              {/* end page title */}
              <div className="row">
                <div className="col-12">
                  <div className="card custom-box-shadow">
                    <form className="" onSubmit={handleFilterSubmit}>
                      <div className="card-header border-bottom">
                        <h5 className="card-title mb-0">Filters</h5>
                      </div>
                      <div className="card-body">
                        <div className="row justify-content-between mb-0">
                          <div className="col-3">
                            <label htmlFor="keyword" className="form-label">
                              Keyword
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Search by name, email or mobile"
                              id="keyword"
                              name="keyword"
                              value={filters.keyword}
                              onChange={handleFilterInputChange}
                            />
                          </div>
                          <div className="col-2">
                            <label htmlFor="state_id" className="form-label">
                              Desired State
                            </label>
                            <select
                              className="form-control"
                              id="state_id"
                              name="state_id"
                              onChange={handleFilterInputChange}
                              value={filters.state_id}
                            >
                              <option value="" key="">
                                Please Select
                              </option>
                              {/* Render the states */}
                              {states.map((state) => (
                                <option key={state.id} value={state.id}>
                                  {state.name}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="col-2">
                            <label htmlFor="speciality_id" className="form-label">
                            Specialties
                            </label>
                            <select
                              className="form-control"
                              name="speciality_id"
                              id="speciality_id"
                              onChange={handleFilterInputChange}
                              value={filters.speciality_id}
                            >
                              <option value="" key="">
                                Please Select
                              </option>
                              {specilityLists.map((facility) => (
                                <option key={facility.id} value={facility.id}>
                                  {facility.specialty}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="col-5">
                            <label htmlFor="speciality_id" className="form-label">
                              Date Range
                            </label>
                            <div className="input-group">
                              <input
                                type="date"
                                name="start_date"
                                className="form-control"
                                value={filters.start_date}
                                onChange={handleFilterInputChange}
                              />
                              <span className="input-group-text">To</span>
                              <input
                                type="date"
                                name="end_date"
                                className="form-control"
                                value={filters.end_date}
                                onChange={handleFilterInputChange}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card-footer border-top">
                        <button type="submit" className="btn btn-primary me-2">
                          Submit
                        </button>
                        <button type="reset" className="btn btn-light" onClick={handleReset}>
                          Reset
                        </button>
                      </div>
                    </form>
                  </div>

                  <div
                    className="card custom-box-shadow"
                    style={{ display: hideUserCard ? "none" : "block" }}
                  >
                    <div className="card-body">
                      <div className="table-responsive">
                        <table className="table table-centered table-nowrap mb-0">
                          <thead className="table-light">
                            <tr>
                              <th>Name</th>
                              <th>Contact Info</th>
                              <th>Desired States</th>
                              <th>Status</th>
                              <th>Registered On</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {loading ? (
                              <tr>
                                <td colSpan="6">Loading...</td>
                              </tr>
                            ) : (
                              <>
                                {noRecordsFound ? (
                                  <tr>
                                    <td colSpan="6">No records found</td>
                                  </tr>
                                ) : (
                                  <>
                                    {Object.entries(applicants).map(([key, value]) => (
                                      <tr key={value.id}>
                                        <td>
                                          <Link
                                            to={`/client/user-profile/` + value.unique_id}
                                            className=""
                                          >
                                            {value.profile_pic_path && (
                                              <img
                                                src={value.profile_pic_path}
                                                className="rounded-circle"
                                                alt="user-img"
                                                height={32}
                                              />
                                            )}{" "}
                                            {value.name}
                                          </Link>
                                        </td>
                                        <td>
                                          <strong>Email</strong> : {value.email}
                                          {value.phone && (
                                            <>
                                              <br />
                                              <strong>Mobile No.</strong> : {value.phone}
                                            </>
                                          )}
                                          {value.state_code && value.city_name ? (
                                            <>
                                              <br />
                                              <strong>Location</strong> : {value.city_name},{" "}
                                              {value.state_code}
                                            </>
                                          ) : value.state_code ? (
                                            <>
                                              <br />
                                              <strong>Location</strong> : {value.state_code}
                                            </>
                                          ) : value.city_name ? (
                                            <>
                                              <br />
                                              <strong>Location</strong> : {value.city_name}
                                            </>
                                          ) : (
                                            ""
                                          )}
                                        </td>
                                        <td>{value.desired_states || "-"}</td>
                                        <td>
                                          {value.user_status == 1 && (
                                            <label className="badge badge-soft-success">
                                              Active
                                            </label>
                                          )}
                                          {value.user_status == 0 && (
                                            <label className="badge badge-soft-warning">
                                              In-Active
                                            </label>
                                          )}
                                          {value.user_status == 2 && (
                                            <label className="badge badge-soft-danger">
                                              Blocked
                                            </label>
                                          )}
                                          {value.user_status == 3 && (
                                            <label className="badge badge-soft-info">
                                              Terminated
                                            </label>
                                          )}
                                        </td>
                                        <td>
                                          {formatDate(value.created_at, "MMM DD, YYYY")}{" "}
                                          <small className="text-muted">
                                            {formatDate(value.created_at, "hh:mm A")}
                                          </small>
                                        </td>
                                        <td>
                                          <Link
                                            to={`/client/user-profile/` + value.unique_id}
                                            className="btn btn-default action-icon"
                                            data-tooltip-id="mt-4"
                                            data-tooltip-content="View Profile"
                                          >
                                            <i className="mdi mdi-eye" />
                                          </Link>
                                          <Tooltip id="mt-4"></Tooltip>
                                        </td>
                                      </tr>
                                    ))}
                                  </>
                                )}
                              </>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  {/* end card*/}
                </div>
                {/* end col */}
              </div>
              {/* end row */}
            </div>
            {/* container */}
          </div>

          <Footer />
        </div>
      </div>

      <ThemeSettings />
      {isLoading === true && <Spinner />}
    </>
  );
}

export default Search;
