import React, { useState, useEffect } from "react";
import Layout from "../../../Layout/Index";
import { Link } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import Modal from "react-bootstrap/Modal";
import * as api from "../../../../services/ApiService";
import * as Yup from "yup";
import * as tokenUtils from "../../../../utils/tokenUtils";
import { toast } from "react-toastify";
import Spinner from "../../../Loader/Spinner";
import PageTitle from "../../../Layout/PageTitle";
import { phoneRegExp } from "../../../../utils/dateFormat";
import useConfirm from "../../../../hooks/useConfirm";
import { formatPhoneNumber } from "../../../../utils/phoneNumberFormatter";

const AdminEmloyeesList = () => {
  // Get data from token util
  let user = tokenUtils.getTokenData();

  const [specilityLists, setspecilityLists] = useState([]);
  const fetchSpecilities = async () => {
    try {
      const param = {
        user_id: user.id,
        token: user.token,
      };
      const apiData = await api.postMethod(param, "admin/get-specialities");

      setspecilityLists(apiData.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchSpecilities();
  }, []); // Empty dependency array ensures that the effect runs only once, when the component mounts

  /* Filters function Start */
  const [showFilterDropdown, setFilterDropdown] = useState(false);
  const handleToggleFilterDropDown = () => {
    setFilterDropdown(!showFilterDropdown);
  };

  // State for filter values
  const [filters, setFilters] = useState({
    keyword: "",
    state_id: "",
    speciality_id: "",
    status: "all",
  });

  // Handle form reset
  const handleReset = () => {
    const defaultFilters = {
      keyword: "",
      state_id: "",
      speciality_id: "",
      status: "all",
    };
    setFilters(defaultFilters);
    setFilterDropdown(false);
  };

  useEffect(() => {
    if (showFilterDropdown === false) {
      fetchApplicants();
    }
  }, [showFilterDropdown]);
  // Handle form input change
  const handleFilterInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      setFilters({
        ...filters,
        [name]: checked,
      });
    } else {
      setFilters({
        ...filters,
        [name]: value,
      });
    }
  };

  // Handle form submit
  const handleFilterSubmit = (e) => {
    e.preventDefault();
    setFilterDropdown(false);
  };
  /* Filters function End */

  const [formData, setformData] = useState({
    id: null,
    user_id: user.id,
    name: null,
    email: null,
    phone: null,
    bio: null,
    state_id: null,
    city_id: null,
    role_id: 9,
    password: "",
  });

  const [isLoading, setIsLoading] = useState(false);

  const [states, setStates] = useState([]);

  useEffect(() => {
    // Function to fetch data from the API
    const fetchStates = async () => {
      try {
        const apiData = await api.getMethod("get-states");
        setStates(apiData.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Call the fetchData function when the component mounts
    fetchStates();
  }, []); // Empty dependency array ensures that the effect runs only once, when the component mounts

  const [cities, setCities] = useState([]);

  useEffect(() => {
    // Function to fetch data from the API
    const fetchData = async () => {
      try {
        const apiData = await api.getMethod("get-cities/" + formData.state_id);

        setCities(apiData.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Call the fetchData function when the component mounts
    fetchData();
  }, [formData.state_id]); // Empty dependency array ensures that the effect runs only once, when the component mounts

  const [applicants, setApplicants] = useState({});
  const [loading, setLoading] = useState(true);
  const [noRecordsFound, setNoRecordsFound] = useState(false);

  const fetchApplicants = async () => {
    try {
      const param = {
        user_id: user.id,
        token: user.token,
        role_id: 9,
      };
      const queryParams = { ...param, ...filters };
      const apiData = await api.postMethod(queryParams, "admin/get-users");
      //const apiDataObj = Object.setPrototypeOf(apiData, Object.prototype);

      setApplicants(apiData.data);
      setLoading(false);
      setNoRecordsFound(false);
      // Check if data array is empty
      if (apiData.data.length === 0) {
        setNoRecordsFound(true);
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    fetchApplicants();
  }, []); // Empty dependency array ensures that the effect runs only once, when the component mounts

  const [uploadAddOpen, setAddModalOpen] = useState(false);

  const toggleAddModal = () => {
    setAddModalOpen(!uploadAddOpen);

    if (!uploadAddOpen) {
      setformData({
        id: null,
        user_id: user.id,
        name: null,
        email: null,
        phone: null,
        bio: null,
        state_id: null,
        city_id: null,
        role_id: 9,
        password: null,
      });

      // Reset validation errors
      setErrors({});
    }
  };

  const handleEdit = (record) => {
    // Populate formData with the values of the selected record
    setformData({
      user_id: user.id,
      id: record.id,
      name: record.name,
      email: record.email,
      phone: record.phone,
      bio: record.bio,
      state_id: record.state_id,
      city_id: record.city_id,
      role_id: 9,
    });

    setAddModalOpen(true);
  };

  const confirm = useConfirm();
  const handleDelete = async (record) => {
    const confirmed = await confirm();
    if (confirmed.isConfirmed) {
      setIsLoading(true);
      record.user_id = user.id;
      const result = await api.postMethod(record, "admin/delete-user");
      setIsLoading(false);
      if (result.status) {
        toast.success(result.message);
        fetchApplicants();
      }
    }
  };

  //Define the validation schema
  const validationSchema = Yup.object({
    name: Yup.string().required("Name is Required"),
    email: Yup.string().email("Invalid Email").required("Email is Required"),
    phone: Yup.string()
      .matches(phoneRegExp, "Phone number is not valid")
      .required("Phone is Required"),
    state_id: Yup.string().required("State is Required"),
    city_id: Yup.string().required("City is Required"),
    password: Yup.string()
      .min(8, "Password must be at least 8 characters")
      .required("Password is required"),
  });

  const [errors, setErrors] = useState({});

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await validationSchema.validate(formData, { abortEarly: false });

      setIsLoading(true);
      const result = await api.postFileMethod(formData, "admin/update-user");
      setIsLoading(false);
      if (result.status) {
        toast.success(result.message);

        //document.getElementById("file_name").value = "";
        setAddModalOpen(false);
        fetchApplicants();
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      setIsLoading(false);
      const newErrors = {};
      if (error.inner && Array.isArray(error.inner)) {
        error.inner.forEach((element) => {
          newErrors[element.path] = element.message;
        });
      } else {
        console.error("Unexpected error structure:", error);
      }
      setErrors(newErrors);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setformData({ ...formData, [name]: value });

    validateFields(name, value);
  };

  const validateFields = (name, value) => {
    try {
      validationSchema.validateSyncAt(name, { [name]: value });

      // Clear the error for the current field if validation passes
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    } catch (error) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: error.message,
      }));
    }
  };

  /* Select All checkbox functions start */

  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const [bulkAction, setBulkAction] = useState("");

  const handleSelectAllChange = (event) => {
    if (event.target.checked) {
      const allUserIds = applicants.map((record) => record.id);
      setSelectedUserIds(allUserIds);
    } else {
      setSelectedUserIds([]);
    }
  };

  const handleCheckboxChange = (event) => {
    const userId = parseInt(event.target.value);
    setSelectedUserIds((prevSelectedUserIds) => {
      if (event.target.checked) {
        // Add the user ID to the list
        return [...prevSelectedUserIds, userId];
      } else {
        // Remove the user ID from the list
        return prevSelectedUserIds.filter((id) => id !== userId);
      }
    });
  };

  const handleBulkActionChange = (event) => {
    setBulkAction(event.target.value);
  };

  const handleBulkActionSubmit = async () => {
    if (bulkAction === "") {
      toast.error("Please any action from bulk actions");
    } else if (selectedUserIds.length === 0) {
      toast.error("Please select atleast 1 record to perform bulk action");
    } else {
      try {
        setIsLoading(true);
        const param = {
          user_id: user.id,
          user_ids: selectedUserIds,
          bulk_action: bulkAction,
          role_id: 9,
        };
        const result = await api.postFileMethod(param, "admin/user-bulk-actions");
        setIsLoading(false);
        if (result.status) {
          toast.success(result.message);
          setSelectedUserIds([]);
          setBulkAction("");
          fetchApplicants();
        } else {
          toast.error(result.message);
        }
      } catch (error) {
        setIsLoading(false);
      }
    }

    /*
    if (bulkAction === "delete") {
      
      axios
        .post("/api/delete-users", { user_ids: selectedUserIds })
        .then((response) => {
          setRecords(records.filter((record) => !selectedUserIds.includes(record.id)));
          setSelectedUserIds([]);
        })
        .catch((error) => console.error("There was an error deleting the users!", error));
      
    } else if (bulkAction === "change-status") {
      
      axios.post('/api/change-status', { user_ids: selectedUserIds, status: 'inactive' })
        .then(response => {
          setRecords(records.map(record => 
            selectedUserIds.includes(record.id) ? { ...record, status: 'inactive' } : record
          ));
          setSelectedUserIds([]);
        })
        .catch(error => console.error('There was an error changing the status!', error));
        
    }
    */
  };

  const updateStatus = async (id, status) => {
    try {
      setIsLoading(true);
      const param = {
        user_id: user.id,
        id: id,
        status: status,
        role_id: 9,
      };
      const result = await api.postFileMethod(param, "admin/update-user-status");
      setIsLoading(false);
      if (result.status) {
        toast.success(result.message);
        fetchApplicants();
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const updateUserFieldValue = async (id, field, value) => {
    try {
      setIsLoading(true);
      const param = {
        user_id: user.id,
        id: id,
        field: field,
        value: value,
        role_id: 9,
      };
      const result = await api.postMethod(param, "admin/update-user-field");
      // Update the state with the new data
      setApplicants(
        applicants.map((record) => (record.id === id ? { ...record, [field]: value } : record))
      );
      fetchApplicants();
      setIsLoading(false);
      if (result.status) {
        toast.success(result.message);
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  };

  const [jobAppStatus, setJobAppStatus] = useState([]);

  useEffect(() => {
    // Function to fetch data from the API
    const fetchJobAppStatus = async () => {
      try {
        const apiData = await api.getMethod("get-job-application-status");
        setJobAppStatus(apiData.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Call the fetchData function when the component mounts
    fetchJobAppStatus();
  }, []); // Empty dependency array ensures that the effect runs only once, when the component mounts

  const breadcrumbItems = [
    { text: "Admin Dashboard", link: "/admin/dashboard" },
    { text: "Employees", link: null },
  ];

  return (
    <Layout>
      <PageTitle title="Employees" breadcrumbItems={breadcrumbItems} />

      {/* end page title */}
      <div className="row">
        <div className="col-12">
          <div className="card custom-box-shadow">
            <div className="card-body">
              <div className="row justify-content-between mb-2">
                <div className="col-md-6">
                  <div className="">
                    <select
                      className="form-select my-1 my-lg-0 w-auto d-inline me-1"
                      value={bulkAction}
                      onChange={handleBulkActionChange}
                    >
                      <option value="">Bulk Actions</option>
                      <option value="delete">Delete</option>
                      <option value="change-status-active">Change Status to Active</option>
                      <option value="change-status-inactive">Change Status to In-Active</option>
                      <option value="convert-to-applicant">Convert to Applicant</option>
                      <option value="convert-to-candidate">Convert to Candidate</option>
                    </select>
                    <button onClick={handleBulkActionSubmit} className="btn btn-info">
                      Apply Action
                    </button>
                  </div>
                </div>
                <div className="col-auto">
                  <div className="d-flex flex-wrap align-items-center">
                    <div className="dropdown me-2" style={{ width: 300 }}>
                      <button
                        type="button"
                        className="btn btn-outline-light dropdown-toggle w-100 text-start"
                        onClick={handleToggleFilterDropDown}
                      >
                        Filters <i className="mdi mdi-chevron-down float-end" />
                      </button>
                      <div className={`dropdown-menu  ${showFilterDropdown ? "show" : ""}`}>
                        <form className="px-2 py-2" onSubmit={handleFilterSubmit}>
                          <div className="mb-2">
                            <label htmlFor="keyword" className="form-label">
                              Keyword
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="keyword"
                              name="keyword"
                              value={filters.keyword}
                              onChange={handleFilterInputChange}
                            />
                          </div>
                          <div className="mb-2">
                            <label htmlFor="state_id" className="form-label">
                              Desired State
                            </label>
                            <select
                              className="form-control"
                              id="state_id"
                              name="state_id"
                              onChange={handleFilterInputChange}
                              value={filters.state_id}
                            >
                              <option value="" key="">
                                Please Select
                              </option>
                              {/* Render the states */}
                              {states.map((state) => (
                                <option key={state.id} value={state.id}>
                                  {state.name}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="mb-2">
                            <label htmlFor="speciality_id" className="form-label">
                            Specialties
                            </label>
                            <select
                              className="form-control"
                              name="speciality_id"
                              id="speciality_id"
                              onChange={handleFilterInputChange}
                              value={filters.speciality_id}
                            >
                              <option value="" key="">
                                Please Select
                              </option>
                              {specilityLists.map((facility) => (
                                <option key={facility.id} value={facility.id}>
                                  {facility.specialty}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="mb-2">
                            <label htmlFor="job_status" className="form-label">
                              Stage
                            </label>
                            <select
                              className="form-control"
                              id="job_status"
                              name="status"
                              onChange={handleFilterInputChange}
                              value={filters.status}
                            >
                              <option value="all">Please Select</option>
                              {jobAppStatus.map((status) => (
                                <>
                                  <option value={status.id} key={status.id}>
                                    {status.title}
                                  </option>
                                </>
                              ))}
                            </select>
                          </div>
                          <div className="text-end">
                            <button
                              type="reset"
                              className="btn btn-light float-start"
                              onClick={handleReset}
                            >
                              Reset Filter
                            </button>
                            <button type="submit" className="btn btn-primary">
                              Apply Filter
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-auto">
                  <div className="text-lg-end my-1 my-lg-0">
                    {/* 
                    <button
                      type="button"
                      className="btn btn-primary waves-effect waves-light mb-2 me-1"
                    >
                      <i className="fas fa-upload mr-1" /> Import Employee
                    </button>
                    
                    <button
                      type="button"
                      className="btn btn-primary waves-effect waves-light mb-2"
                      onClick={toggleAddModal}
                    >
                      <i className="fa fa-plus mr-1" /> Add New Employee
                    </button>*/}
                    <Link
                      to="/admin/add-employee-step1"
                      className="btn btn-primary waves-effect waves-light mb-2"
                    >
                      <i className="fa fa-plus mr-1" /> Add New Employee
                    </Link>
                  </div>
                </div>
              </div>

              <div className="table-responsive">
                <table className="table table-centered mb-0">
                  <thead className="table-light">
                    <tr>
                      <th className="form-checks">
                        <input
                          type="checkbox"
                          onChange={handleSelectAllChange}
                          checked={
                            selectedUserIds.length === applicants.length && applicants.length > 0
                          }
                          className="form-check-input"
                        />
                      </th>

                      <th>Name</th>
                      <th>Contact Info</th>
                      <th>Desired States</th>
                      <th>Applied Job</th>
                      <th>Status</th>
                      <th>Registered By</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <tr>
                        <td colSpan="8">Loading...</td>
                      </tr>
                    ) : (
                      <>
                        {noRecordsFound ? (
                          <tr>
                            <td colSpan="8">No records found</td>
                          </tr>
                        ) : (
                          <>
                            {Object.entries(applicants).map(([key, value]) => (
                              <tr key={value.id}>
                                <td className="form-checks">
                                  <input
                                    type="checkbox"
                                    value={value.id}
                                    name="user_ids"
                                    onChange={handleCheckboxChange}
                                    checked={selectedUserIds.includes(value.id)}
                                    className="form-check-input"
                                  />
                                </td>
                                <td>
                                  <Link to={`/admin/user-profile/` + value.unique_id} className="">
                                    {value.profile_pic_path && (
                                      <>
                                        <img
                                          src={value.profile_pic_path}
                                          className="rounded-circle"
                                          alt="user-img"
                                          height={32}
                                        />
                                        <br />
                                      </>
                                    )}{" "}
                                    {value.name}
                                  </Link>
                                  {value.profession && (
                                    <>
                                      <br />
                                      {value.profession}
                                    </>
                                  )}
                                </td>
                                <td>
                                  <strong>Email</strong> : {value.email}
                                  {value.phone && (
                                    <>
                                      <br />
                                      <strong>Mobile No.</strong> :{" "}
                                      {formatPhoneNumber(value.phone, "1")}
                                    </>
                                  )}
                                  {value.state_code && value.city_name ? (
                                    <>
                                      <br />
                                      <strong>Location</strong> : {value.city_name},{" "}
                                      {value.state_code}
                                    </>
                                  ) : value.state_code ? (
                                    <>
                                      <br />
                                      <strong>Location</strong> : {value.state_code}
                                    </>
                                  ) : value.city_name ? (
                                    <>
                                      <br />
                                      <strong>Location</strong> : {value.city_name}
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </td>
                                <td>{value.desired_states || "-"}</td>
                                <td>
                                  {(value.job_title && value.job_status) || value.specialty ? (
                                    <>
                                      {value.job_title && (
                                        <>
                                          <span>Job Title : </span>
                                          <Link
                                            to={`/admin/job/${value.job_unique_id}`}
                                            className=""
                                          >
                                            {value.job_title}
                                          </Link>
                                        </>
                                      )}
                                      {value.specialty && (
                                        <>
                                          <br />
                                          <span>Specialty : </span>{" "}
                                          <span className="text-dark">{value.specialty}</span>
                                        </>
                                      )}
                                      {value.job_status && (
                                        <>
                                          <br />
                                          <span>Application Status : </span>{" "}
                                          {(value.job_status == 1 && (
                                            <label className="badge badge-soft-primary">
                                              Applied
                                            </label>
                                          )) ||
                                            (value.job_status == 2 && (
                                              <label className="badge badge-soft-info">
                                                Shortlisted
                                              </label>
                                            )) ||
                                            (value.job_status == 3 && (
                                              <label className="badge badge-soft-info">
                                                Submitted
                                              </label>
                                            )) ||
                                            (value.job_status == 4 && (
                                              <label className="badge badge-soft-warning">
                                                Interviewing
                                              </label>
                                            )) ||
                                            (value.job_status == 5 && (
                                              <label className="badge badge-soft-secondary">
                                                Offered
                                              </label>
                                            )) ||
                                            (value.job_status == 6 && (
                                              <label className="badge badge-soft-success">
                                                Hired
                                              </label>
                                            )) ||
                                            (value.job_status == 7 && (
                                              <label className="badge badge-soft-danger">
                                                Rejected
                                              </label>
                                            )) ||
                                            "-"}
                                        </>
                                      )}
                                    </>
                                  ) : (
                                    "-"
                                  )}
                                </td>
                                <td>
                                  {value.user_status == 1 && (
                                    <label className="badge badge-soft-success">Active</label>
                                  )}
                                  {value.user_status == 0 && (
                                    <label className="badge badge-soft-warning">In-Active</label>
                                  )}
                                  {value.user_status == 2 && (
                                    <label className="badge badge-soft-danger">Blocked</label>
                                  )}
                                  {value.user_status == 3 && (
                                    <label className="badge badge-soft-info">Terminated</label>
                                  )}
                                </td>
                                <td>
                                  {(value.creator_role_id == 2 && (
                                    <Link
                                      to={`/admin/facility-profile/` + value.creator_unique_id}
                                      className=""
                                    >
                                      {value.creator_name}
                                    </Link>
                                  )) ||
                                    (value.creator_role_id == 3 && (
                                      <Link
                                        to={`/admin/agency-profile/` + value.creator_unique_id}
                                        className=""
                                      >
                                        {value.creator_name}
                                      </Link>
                                    )) || <span className="">{value.creator_name}</span>}
                                </td>
                                <td>
                                  {value.user_status == 0 && (
                                    <>
                                      <button
                                        type="button"
                                        className="btn btn-default action-icon"
                                        data-tooltip-id="mt-1"
                                        data-tooltip-content="In-Active"
                                        onClick={() => updateStatus(value.id, 1)}
                                      >
                                        <i className="mdi mdi-close-box-outline" />
                                      </button>
                                      <Tooltip id="mt-1"></Tooltip>
                                    </>
                                  )}
                                  {value.user_status == 1 && (
                                    <>
                                      <button
                                        type="button"
                                        className="btn btn-default action-icon"
                                        data-tooltip-id="mt-1"
                                        data-tooltip-content="Active"
                                        onClick={() => updateStatus(value.id, 0)}
                                      >
                                        <i className="mdi mdi-check-box-outline" />
                                      </button>
                                      <Tooltip id="mt-1"></Tooltip>
                                    </>
                                  )}

                                  <Link
                                    className="btn btn-default action-icon"
                                    data-tooltip-id="mt-2"
                                    data-tooltip-content="Edit Employee"
                                    to={`/admin/add-employee-step1/` + value.unique_id}
                                  >
                                    <i className="mdi mdi-pencil" />
                                  </Link>
                                  <Tooltip id="mt-2"></Tooltip>

                                  {/* 
                                  <button
                                    type="button"
                                    className="btn btn-default action-icon"
                                    data-tooltip-id="mt-2"
                                    data-tooltip-content="Edit Employee"
                                    onClick={() => handleEdit(value)}
                                  >
                                    <i className="mdi mdi-pencil" />
                                  </button>
                                  <Tooltip id="mt-2"></Tooltip>
*/}
                                  <button
                                    type="button"
                                    className="btn btn-default action-icon"
                                    data-tooltip-id="mt-3"
                                    data-tooltip-content="Convert to Applicant"
                                    onClick={() => updateUserFieldValue(value.id, "role_id", 5)}
                                  >
                                    <i className="mdi mdi-autorenew" />
                                  </button>
                                  <Tooltip id="mt-3"></Tooltip>

                                  <button
                                    type="button"
                                    className="btn btn-default action-icon"
                                    data-tooltip-id="mt-6"
                                    data-tooltip-content="Convert to Candidate"
                                    onClick={() => updateUserFieldValue(value.id, "role_id", 4)}
                                  >
                                    <i className="mdi mdi-autorenew" />
                                  </button>
                                  <Tooltip id="mt-6"></Tooltip>

                                  <Link
                                    to={`/admin/user-profile/` + value.unique_id}
                                    className="btn btn-default action-icon"
                                    data-tooltip-id="mt-4"
                                    data-tooltip-content="View Profile"
                                  >
                                    <i className="mdi mdi-eye" />
                                  </Link>
                                  <Tooltip id="mt-4"></Tooltip>

                                  <button
                                    type="button"
                                    className="btn btn-default action-icon"
                                    data-tooltip-id="mt-5"
                                    data-tooltip-content="Remove Employee"
                                    onClick={() => handleDelete(value)}
                                  >
                                    <i className="mdi mdi-delete" />
                                  </button>
                                  <Tooltip id="mt-5"></Tooltip>
                                </td>
                              </tr>
                            ))}
                          </>
                        )}
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            {/* end card-body*/}
          </div>
          {/* end card*/}
        </div>
        {/* end col */}
      </div>

      <Modal show={uploadAddOpen} onHide={toggleAddModal} centered size="lg">
        <form onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title className="my-0">Add Employee</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-4 mb-2">
                <label className="form-label" htmlFor="name">
                  Full Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  name="name"
                  onChange={handleChange}
                  defaultValue={formData.name !== null ? formData.name : ""}
                />
                {errors.name && <span className="error">{errors.name}</span>}
              </div>

              <div className="col-md-4 mb-2">
                <label className="form-label" htmlFor="email">
                  Email
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="email"
                  name="email"
                  onChange={handleChange}
                  defaultValue={formData.email !== null ? formData.email : ""}
                />
                {errors.email && <span className="error">{errors.email}</span>}
              </div>

              <div className="col-md-4 mb-2">
                <label className="form-label" htmlFor="password">
                  Password
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="password"
                  name="password"
                  onChange={handleChange}
                />
                {errors.password && <span className="error">{errors.password}</span>}
              </div>

              <div className="col-md-4 mb-2">
                <label className="form-label" htmlFor="phone">
                  Phone Number
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="phone"
                  name="phone"
                  onChange={handleChange}
                  defaultValue={formData.phone !== null ? formData.phone : ""}
                />
                {errors.phone && <span className="error">{errors.phone}</span>}
              </div>

              <div className="col-md-4 ">
                <div className="mb-2">
                  <label htmlFor="state_id" className="form-label">
                    State
                  </label>
                  <select
                    className="form-control"
                    name="state_id"
                    onChange={handleChange}
                    value={formData.state_id !== null ? formData.state_id : ""}
                  >
                    <option value="" key="">
                      Please Select
                    </option>
                    {/* Render the states */}
                    {states.map((state) => (
                      <option key={state.id} value={state.id}>
                        {state.name}
                      </option>
                    ))}
                  </select>

                  {errors.state_id && <span className="error">{errors.state_id}</span>}
                </div>
              </div>

              <div className="col-md-4">
                <div className="mb-2">
                  <label htmlFor="city_id" className="form-label">
                    City
                  </label>
                  <select
                    className="form-control"
                    name="city_id"
                    onChange={handleChange}
                    value={formData.city_id !== null ? formData.city_id : ""}
                  >
                    <option value="" key="">
                      Please Select
                    </option>
                    {/* Render the states */}
                    {cities.map((city) => (
                      <option key={city.id} value={city.id}>
                        {city.city_name}
                      </option>
                    ))}
                  </select>
                  {errors.city_id && <span className="error">{errors.city_id}</span>}
                </div>
              </div>
              <div className="col-md-12 mb-0">
                <label className="form-label" htmlFor="bio">
                  Bio
                </label>
                <textarea
                  rows={5}
                  className="form-control"
                  id="bio"
                  name="bio"
                  onChange={handleChange}
                  defaultValue={formData.bio !== null ? formData.bio : ""}
                ></textarea>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button type="button" className="btn btn-secondary" onClick={toggleAddModal}>
              Close
            </button>
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </Modal.Footer>
        </form>
      </Modal>

      {isLoading === true && <Spinner />}
    </Layout>
  );
};

export default AdminEmloyeesList;
