import React, { useState, useEffect } from "react";
import Layout from "../../Layout/Index";
import { Link } from "react-router-dom";
import * as api from "../../../services/ApiService";
import { Tooltip } from "react-tooltip";
import * as tokenUtils from "../../../utils/tokenUtils";
import * as Yup from "yup";
import Spinner from "../../Loader/Spinner";
import { toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";
import { formatDate } from "../../../utils/dateFormat";
import PageTitle from "../../Layout/PageTitle";
import useConfirm from "../../../hooks/useConfirm";

const Submissions = () => {
  // Get data from token util
  let user = tokenUtils.getTokenData();

  /* Filters function Start */
  const [showFilterDropdown, setFilterDropdown] = useState(false);
  const handleToggleFilterDropDown = () => {
    setFilterDropdown(!showFilterDropdown);
  };

  // State for filter values
  const [filters, setFilters] = useState({
    keyword: "",
    start_date: "",
    end_date: "",
    status: "all",
  });

  // Handle form reset
  const handleReset = () => {
    const defaultFilters = {
      keyword: "",
      start_date: "",
      end_date: "",
      status: "all",
    };
    setFilters(defaultFilters);
    fetchComplianceFiles();
    setFilterDropdown(false);
  };

  useEffect(() => {
    if (showFilterDropdown === false) {
      fetchComplianceFiles();
    }
  }, [showFilterDropdown]);

  // Handle form input change
  const handleFilterInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      setFilters({
        ...filters,
        [name]: checked,
      });
    } else {
      setFilters({
        ...filters,
        [name]: value,
      });
    }
  };

  // Handle form submit
  const handleFilterSubmit = (e) => {
    e.preventDefault();
    setFilterDropdown(false);
  };
  /* Filters function End */

  const [UserLists, setUserLists] = useState([]);
  const fetchUserLists = async () => {
    try {
      const apiData = await api.getMethod("get-users/4");

      setUserLists(apiData.data);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUserLists();
  }, []); // Empty dependency array ensures that the effect runs only once, when the component mounts

  /*
  const [facilityLists, setFacilityLists] = useState([]);
  const fetchFacilities = async () => {
    try {
      const param = {
        user_id: user.id,
        token: user.token,
      };
      const apiData = await api.postMethod(param, "admin/get-clients");

      setFacilityLists(apiData.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchFacilities();
  }, []); // Empty dependency array ensures that the effect runs only once, when the component mounts
  */

  const [specilityLists, setspecilityLists] = useState([]);
  const fetchSpecilities = async () => {
    try {
      const param = {
        user_id: user.id,
        token: user.token,
      };
      const apiData = await api.postMethod(param, "admin/get-specialities");

      setspecilityLists(apiData.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchSpecilities();
  }, []); // Empty dependency array ensures that the effect runs only once, when the component mounts

  const [isLoading, setIsLoading] = useState(false);
  const [complianceFiles, setComplianceFiles] = useState({});
  const [loading, setLoading] = useState(true);
  const [noRecordsFound, setNoRecordsFound] = useState(false);

  const fetchComplianceFiles = async () => {
    try {
      const param = {
        user_id: user.id,
        token: user.token,
      };
      const queryParams = { ...param, ...filters };
      const apiData = await api.postMethod(queryParams, "admin/get-submissions");

      setComplianceFiles(apiData.data);
      setLoading(false);
      // Check if data array is empty
      if (apiData.data.length === 0) {
        setNoRecordsFound(true);
      } else setNoRecordsFound(false);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    fetchComplianceFiles();
  }, []); // Empty dependency array ensures that the effect runs only once, when the component mounts

  const [formData, setformData] = useState({
    id: null,
    user_id: user.id,
    entry_date: null,
    candidate_id: null,
    client_id: null,
    client_title: null,
    specialty_id: null,
    agency_id: null,
  });

  const [uploadModalOpen, setUploadModalOpen] = useState(false);

  const toggleUploadModal = () => {
    setUploadModalOpen(!uploadModalOpen);

    if (!uploadModalOpen) {
      setformData({
        id: null,
        user_id: user.id,
        entry_date: null,
        candidate_id: null,
        client_id: null,
        client_title: null,
        specialty_id: null,
        agency_id: null,
      });

      // Reset validation errors
      setErrors({});
    }
  };

  const handleEdit = (record) => {
    // Populate formData with the values of the selected record
    setformData({
      user_id: user.id,
      id: record.id,
      entry_date: record.entry_date,
      candidate_id: record.candidate_id,
      client_id: record.client_id,
      client_title: record.client_title,
      specialty_id: record.specialty_id,
      agency_id: record.agency_id,
    });

    setUploadModalOpen(true);
  };

  const { confirm } = useConfirm();
  const handleDelete = async (record) => {
    const confirmed = await confirm();
    if (confirmed.isConfirmed) {
      record.user_id = user.id;
      setIsLoading(true);
      const result = await api.postMethod(record, "admin/delete-submission");
      setIsLoading(false);
      if (result.status) {
        toast.success(result.message);
        fetchComplianceFiles();
      }
    }
  };

  //Define the validation schema
  const validationSchema = Yup.object({
    entry_date: Yup.string().required("Date is Required"),
    candidate_id: Yup.string().required("Candidate is Required"),
    /*client_id: Yup.string().required("Facility is Required"),*/
    client_title: Yup.string().required("Facility is Required"),
    specialty_id: Yup.string().required("Specialty is Required"),
  });

  const [errors, setErrors] = useState({});
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await validationSchema.validate(formData, { abortEarly: false });

      setIsLoading(true);
      const result = await api.postFileMethod(formData, "admin/update-submission");
      setIsLoading(false);
      if (result.status) {
        toast.success(result.message);

        //document.getElementById("file_name").value = "";
        setUploadModalOpen(false);
        fetchComplianceFiles();
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      setIsLoading(false);
      const newErrors = {};
      if (error.inner && Array.isArray(error.inner)) {
        error.inner.forEach((element) => {
          newErrors[element.path] = element.message;
        });
      } else {
        console.error("Unexpected error structure:", error);
      }
      setErrors(newErrors);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setformData({ ...formData, [name]: value });

    validateFields(name, value);
  };

  const validateFields = (name, value) => {
    try {
      validationSchema.validateSyncAt(name, { [name]: value });

      // Clear the error for the current field if validation passes
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    } catch (error) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: error.message,
      }));
    }
  };

  const handleSelectChange = (id, field, value) => {
    // Call the function to update the particular field value
    updateFieldValue(id, field, value);
  };

  const updateFieldValue = async (id, field, value) => {
    /*
    axios.put(`/api/records/${id}`, { [field]: value })
      .then(response => {
        // Update the state with the new data
        setComplianceFiles(complianceFiles.map(record =>
          record.id === id ? { ...record, [field]: value } : record
        ));
      })
      .catch(error => {
        console.error('There was an error updating the field!', error);
      });
    */

    try {
      setIsLoading(true);
      const param = {
        user_id: user.id,
        id: id,
        field: field,
        value: value,
      };
      const result = await api.postMethod(param, "admin/update-submission-field");
      // Update the state with the new data
      setComplianceFiles(
        complianceFiles.map((record) => (record.id === id ? { ...record, [field]: value } : record))
      );

      setIsLoading(false);
      if (result.status) {
        toast.success(result.message);
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  };
  const breadcrumbItems = [
    { text: "Admin Dashboard", link: "/admin/dashboard" },
    { text: "Submissions", link: null },
  ];

  return (
    <Layout>
      <PageTitle title="Submissions" breadcrumbItems={breadcrumbItems} />

      {/* end page title */}
      <div className="row">
        <div className="col-12">
          <div className="card custom-box-shadow">
            <div className="card-body">
              <div className="row justify-content-between mb-2">
                <div className="col-auto">
                  <div className="dropdown" style={{ width: 300 }}>
                    <button
                      type="button"
                      className="btn btn-outline-light dropdown-toggle w-100 text-start"
                      onClick={handleToggleFilterDropDown}
                    >
                      Filters
                      <i className="mdi mdi-chevron-down float-end" />
                    </button>
                    <div className={`dropdown-menu ${showFilterDropdown ? "show" : ""}`}>
                      <form className="px-2 py-2" onSubmit={handleFilterSubmit}>
                        <div className="mb-2">
                          <label htmlFor="keyword" className="form-label">
                            Keyword
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="keyword"
                            name="keyword"
                            value={filters.keyword}
                            onChange={handleFilterInputChange}
                          />
                        </div>

                        <div className="mb-2">
                          <label htmlFor="start_date" className="form-label">
                            Date Range
                          </label>
                          <div className="input-group">
                            <input
                              type="date"
                              name="start_date"
                              className="form-control"
                              value={filters.start_date}
                              onChange={handleFilterInputChange}
                            />
                            <span className="input-group-text">To</span>
                            <input
                              type="date"
                              name="end_date"
                              className="form-control"
                              value={filters.end_date}
                              onChange={handleFilterInputChange}
                            />
                          </div>
                        </div>

                        <div className="mb-2">
                          <label htmlFor="status" className="form-label">
                            Status
                          </label>
                          <select
                            className="form-select"
                            id="cstatus"
                            name="status"
                            value={filters.status}
                            onChange={handleFilterInputChange}
                          >
                            <option value={"all"}>Please Select</option>
                            <option value="open">Open</option>
                            <option value="reviewing">Reviewing</option>
                            <option value="interviewing">Interviewing</option>
                            <option value="interviewed">Interviewed</option>
                            <option value="offered">Offered</option>
                            <option value="booked">Booked</option>
                            <option value="closed">Closed</option>
                            <option value="redirect">Redirect</option>
                            <option value="paused">Paused</option>
                            <option value="frozen">Frozen</option>
                            <option value="retracted">Retracted</option>
                            <option value="declined">Declined</option>
                          </select>
                        </div>

                        <div className="text-end mt-1">
                          <button
                            type="button"
                            className="btn btn-light float-start"
                            onClick={handleReset}
                          >
                            Reset Filter
                          </button>
                          <button type="submit" className="btn btn-primary">
                            Apply Filter
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="col-auto">
                  <div className="text-lg-end my-1 my-lg-0">
                    <button
                      type="button"
                      className="btn btn-primary waves-effect waves-light mb-2"
                      onClick={toggleUploadModal}
                    >
                      <i className="fa fa-plus mr-1" /> Add New
                    </button>
                  </div>
                </div>
              </div>

              <div className="table-responsive">
                <table className="table table-centered table-nowrap mb-0">
                  <thead className="table-light">
                    <tr>
                      <th>Date</th>
                      <th>Candidate</th>
                      <th>Client</th>
                      <th>Specialty</th>
                      <th>Job Status</th>
                      {/*<th>No. of Submissions</th>*/}
                      <th>Submitted By</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <tr>
                        <td colSpan="7">Loading...</td>
                      </tr>
                    ) : (
                      <>
                        {noRecordsFound ? (
                          <tr>
                            <td colSpan="7">No records found</td>
                          </tr>
                        ) : (
                          <>
                            {Object.entries(complianceFiles).map(([key, value]) => (
                              <tr key={value.id}>
                                <td>{value.entry_date}</td>
                                <td>
                                  <Link
                                    to={`/admin/user-profile/` + value.user_unique_id}
                                    className=""
                                  >
                                    {value.name}
                                  </Link>
                                </td>
                                <td>{value.client_title || "-"}</td>
                                <td>{value.specialty_title || "-"}</td>
                                <td>
                                  <select
                                    className="form-select my-1 my-lg-0"
                                    onChange={(e) =>
                                      handleSelectChange(value.id, "status", e.target.value)
                                    }
                                    value={value.status}
                                  >
                                    <option value="">-</option>
                                    <option value="open">Open</option>
                                    <option value="reviewing">Reviewing</option>
                                    <option value="interviewing">Interviewing</option>
                                    <option value="interviewed">Interviewed</option>
                                    <option value="offered">Offered</option>
                                    <option value="booked">Booked</option>
                                    <option value="closed">Closed</option>
                                    <option value="redirect">Redirect</option>
                                    <option value="paused">Paused</option>
                                    <option value="frozen">Frozen</option>
                                    <option value="retracted">Retracted</option>
                                    <option value="declined">Declined</option>
                                  </select>
                                </td>
                                {/* 
                                <td>
                                  <select
                                    className="form-select my-1 my-lg-0"
                                    onChange={(e) =>
                                      handleSelectChange(
                                        value.id,
                                        "no_of_submissions",
                                        e.target.value
                                      )
                                    }
                                    value={value.no_of_submissions}
                                  >
                                    <option value="">-</option>
                                    <option value="high">High</option>
                                    <option value="med">Med</option>
                                    <option value="low">Low</option>
                                    <option value="na">N/A</option>
                                  </select>
                                </td>
                                */}
                                <td>{value.submitted_user_name}</td>
                                <td>
                                  <button
                                    type="button"
                                    className="btn btn-default action-icon"
                                    data-tooltip-id="mt-3"
                                    data-tooltip-content="Edit Submission"
                                    onClick={() => handleEdit(value)}
                                  >
                                    <i className="mdi mdi-pencil" />
                                  </button>
                                  <Tooltip id="mt-3"></Tooltip>
                                  <button
                                    type="button"
                                    className="btn btn-default action-icon"
                                    data-tooltip-id="mt-2"
                                    data-tooltip-content="Remove Submission"
                                    onClick={() => handleDelete(value)}
                                  >
                                    <i className="mdi mdi-delete" />
                                  </button>
                                  <Tooltip id="mt-2"></Tooltip>
                                </td>
                              </tr>
                            ))}
                          </>
                        )}
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            {/* end card-body*/}
          </div>
          {/* end card*/}
        </div>
        {/* end col */}
      </div>

      <Modal show={uploadModalOpen} onHide={toggleUploadModal} centered backdrop="static" size="md">
        <form onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title className="my-0">Add Submission</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-12 mb-2">
                <label className="form-label" htmlFor="entry_date">
                  Date *
                </label>
                <input
                  type="date"
                  className="form-control"
                  id="entry_date"
                  name="entry_date"
                  onChange={handleChange}
                  defaultValue={formData.entry_date !== null ? formData.entry_date : ""}
                />
                {errors.entry_date && <span className="error">{errors.entry_date}</span>}
              </div>

              <div className="mb-2 col-md-12">
                <label className="form-label" htmlFor="candidate_id">
                  Candidate *
                </label>
                <select
                  className="form-control"
                  id="candidate_id"
                  name="candidate_id"
                  onChange={handleChange}
                  value={formData.candidate_id !== null ? formData.candidate_id : ""}
                >
                  <option value="" key="">
                    Please Select
                  </option>
                  {/* Render the states */}
                  {UserLists.map((user) => (
                    <option key={user.id} value={user.id}>
                      {user.name}
                    </option>
                  ))}
                </select>
                {errors.candidate_id && <span className="error">{errors.candidate_id}</span>}
              </div>

              {/* 
              <div className="mb-2 col-md-12">
                <label className="form-label" htmlFor="client_id">
                  Facility List *
                </label>
                <select
                  className="form-control"
                  id="client_id"
                  name="client_id"
                  onChange={handleChange}
                  value={formData.client_id !== null ? formData.client_id : ""}
                >
                  <option value="" key="">
                    Please Select
                  </option>
                  {facilityLists.map((facility) => (
                    <option key={facility.id} value={facility.id}>
                      {facility.title}
                    </option>
                  ))}
                </select>
                {errors.client_id && <span className="error">{errors.client_id}</span>}
              </div>
              */}

              <div className="col-md-12 mb-2">
                <label className="form-label" htmlFor="client_title">
                  Facility *
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="client_title"
                  name="client_title"
                  onChange={handleChange}
                  defaultValue={formData.client_title !== null ? formData.client_title : ""}
                />
                {errors.client_title && <span className="error">{errors.client_title}</span>}
              </div>

              <div className="mb-2 col-md-12">
                <label className="form-label" htmlFor="specialty_id">
                Specialties *
                </label>
                <select
                  className="form-control"
                  id="specialty_id"
                  name="specialty_id"
                  onChange={handleChange}
                  value={formData.specialty_id !== null ? formData.specialty_id : ""}
                >
                  <option value="" key="">
                    Please Select
                  </option>
                  {/* Render the states */}
                  {specilityLists.map((facility) => (
                    <option key={facility.id} value={facility.id}>
                      {facility.specialty}
                    </option>
                  ))}
                </select>
                {errors.specialty_id && <span className="error">{errors.specialty_id}</span>}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button type="button" className="btn btn-secondary" onClick={toggleUploadModal}>
              Close
            </button>
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </Modal.Footer>
        </form>
      </Modal>
      {isLoading === true && <Spinner />}
    </Layout>
  );
};

export default Submissions;
