import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import * as api from "../../../../../services/ApiService";
import * as tokenUtils from "../../../../../utils/tokenUtils";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";

const ReferenceForm = ({
  index,
  reference,
  handleChange,
  getReferenceStatus,
  fetchReferences,
  startRef,
}) => {
  // Get data from token util
  let user = tokenUtils.getTokenData();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const isSubmissionFileRef = queryParams.get("ref") === "submission-file";

  const [formData, setformData] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const viewRef = (refDetails) => {
    setformData(refDetails);
    setModalOpen(true);
  };

  return (
    <div>
      <h5>
        Reference {index + 1}{" "}
        {getReferenceStatus(reference) === "view-reference" && (
          <p className="view-reference btn btn-success btn-sm" onClick={() => viewRef(reference)}>
            <i class="mdi mdi-check-circle mr-1"></i> View Reference
          </p>
        )}
        {getReferenceStatus(reference) === "start-reference" && (
          <p className="start-reference btn btn-info btn-sm" onClick={() => startRef(reference)}>
            <i class="mdi mdi-minus mr-1"></i> Start Reference
          </p>
        )}
        {getReferenceStatus(reference) === "remind-reference" && (
          <p className="remind-reference btn btn-danger btn-sm" onClick={() => startRef(reference)}>
            <i class="mdi mdi-history mr-1"></i> Remind Reference
          </p>
        )}
      </h5>
      <div className="row">
        <div className="col-md-6 mb-2">
          <label className="form-label">Title</label>
          <input
            id="title"
            type="text"
            name="title"
            value={reference.title}
            onChange={(e) => handleChange(e, index)}
            className="form-control"
          />
        </div>

        <div className={`col-md-6 mb-2 ${getReferenceStatus(reference)}`}>
          <label className="form-label">Name</label>
          <input
            type="text"
            name="name"
            value={reference.name}
            onChange={(e) => handleChange(e, index)}
            className="form-control"
          />
        </div>

        <div className={`col-md-6 mb-2 ${getReferenceStatus(reference)}`}>
          <label className="form-label">Email</label>
          <input
            type="email"
            name="email"
            value={reference.email}
            onChange={(e) => handleChange(e, index)}
            className="form-control"
          />
        </div>

        <div className="col-md-6 mb-2">
          <label className="form-label">Phone</label>
          <input
            type="tel"
            name="phone"
            value={reference.phone}
            onChange={(e) => handleChange(e, index)}
            className="form-control"
          />
        </div>
      </div>

      <Modal show={modalOpen} onHide={toggleModal} centered backdrop="static" size="lg">
        <Modal.Header closeButton>
          <Modal.Title className="my-0">View Reference Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 className="border-bottom pb-2">Reference Completed By</h5>
          <div className="row">
            <div className="col-md-3">
              <div className="mb-2">
                <label className="form-label fw-bold">Name</label>
                <p>{formData.reference_by_name || "-"}</p>
              </div>
            </div>

            <div className="col-md-3">
              <div className="mb-2">
                <label className="form-label fw-bold">Title</label>
                <p>{formData.reference_by_title || "-"}</p>
              </div>
            </div>

            <div className="col-md-3">
              <div className="mb-2">
                <label className="form-label fw-bold">Phone</label>
                <p>{formData.reference_by_phone || "-"}</p>
              </div>
            </div>

            <div className="col-md-3">
              <div className="mb-2">
                <label className="form-label fw-bold">Email</label>
                <p>{formData.reference_by_email || "-"}</p>
              </div>
            </div>

            <div className="col-md-3">
              <div className="mb-2">
                <label className="form-label fw-bold">Signature</label>
                <p>{formData.reference_by_signature || "-"}</p>
              </div>
            </div>

            <div className="col-md-3">
              <div className="mb-2">
                <label className="form-label fw-bold">Date</label>
                <p>{formData.reference_by_signature_date || "-"}</p>
              </div>
            </div>

            <div className="col-md-3">
              <div className="mb-2">
                <label className="form-label fw-bold">Reference Information Verified By</label>
                <p>{formData.reference_by_verified_by || "-"}</p>
              </div>
            </div>

            <div className="col-md-3">
              <div className="mb-2">
                <label className="form-label fw-bold">Date</label>
                <p>{formData.reference_by_verified_by_date || "-"}</p>
              </div>
            </div>
          </div>

          <h5 className="border-bottom pb-2">Employment Information</h5>
          <div className="row">
            <div className="col-md-4">
              <div className="mb-2">
                <label className="form-label fw-bold">Facility</label>
                <p>{formData.facility || "-"}</p>
              </div>
            </div>

            <div className="col-md-4">
              <div className="mb-2">
                <label className="form-label fw-bold">Dates of Employment</label>
                <p>{formData.dates_of_employment || "-"}</p>
              </div>
            </div>

            <div className="col-md-4">
              <div className="mb-2">
                <label className="form-label fw-bold">Address</label>
                <p>{formData.address || "-"}</p>
              </div>
            </div>

            <div className="col-md-4">
              <div className="mb-2">
                <label className="form-label fw-bold">Title while Employed</label>
                <p>{formData.title_while_employed || "-"}</p>
              </div>
            </div>

            <div className="col-md-4">
              <div className="mb-2">
                <label className="form-label fw-bold">Phone</label>
                <p>{formData.phone || "-"}</p>
              </div>
            </div>

            <div className="col-md-4">
              <div className="mb-2">
                <label className="form-label fw-bold">Specialty Worked</label>
                <p>{formData.specialty_worked || "-"}</p>
              </div>
            </div>

            <div className="col-md-12">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th></th>
                    <th className="text-center">Yes</th>
                    <th className="text-center">No</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th>1. How long have you worked with this candidate?</th>
                    <td className="text-center">
                      {formData.worked_with === "Yes" && <i class="fas fa-check"></i>}
                    </td>
                    <td className="text-center">
                      {formData.worked_with === "No" && <i class="fas fa-check"></i>}
                    </td>
                  </tr>
                  <tr>
                    <th>2. Is this person honest, reliable, and trustworthy?</th>
                    <td className="text-center">
                      {formData.honest_reliable === "Yes" && <i class="fas fa-check"></i>}
                    </td>
                    <td className="text-center">
                      {formData.honest_reliable === "No" && <i class="fas fa-check"></i>}
                    </td>
                  </tr>
                  <tr>
                    <th>3. Was this candidate on a travel assignment?</th>
                    <td className="text-center">
                      {formData.travel_assignment === "Yes" && <i class="fas fa-check"></i>}
                    </td>
                    <td className="text-center">
                      {formData.travel_assignment === "No" && <i class="fas fa-check"></i>}
                    </td>
                  </tr>
                  <tr>
                    <th>4. Is this candidate eligible for rehire?</th>
                    <td className="text-center">
                      {formData.eligible_rehire === "Yes" && <i class="fas fa-check"></i>}
                    </td>
                    <td className="text-center">
                      {formData.eligible_rehire === "No" && <i class="fas fa-check"></i>}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <h5 className="border-bottom pb-2">Candidate Employment Evaluation</h5>

          <div className="row">
            <div className="col-md-12">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>Criteria</th>
                    <th className="text-center">Poor</th>
                    <th className="text-center">Fair</th>
                    <th className="text-center">Average</th>
                    <th className="text-center">Good</th>
                    <th className="text-center">Excellent</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th>1. Quality of Work</th>
                    <td className="text-center">
                      {formData.quality_of_work === "Poor" && <i class="fas fa-check"></i>}
                    </td>
                    <td className="text-center">
                      {formData.quality_of_work === "Fair" && <i class="fas fa-check"></i>}
                    </td>
                    <td className="text-center">
                      {formData.quality_of_work === "Average" && <i class="fas fa-check"></i>}
                    </td>
                    <td className="text-center">
                      {formData.quality_of_work === "Good" && <i class="fas fa-check"></i>}
                    </td>
                    <td className="text-center">
                      {formData.quality_of_work === "Excellent" && <i class="fas fa-check"></i>}
                    </td>
                  </tr>
                  <tr>
                    <th>2. Clinical Knowledge/Skills</th>
                    <td className="text-center">
                      {formData.clinical_knowledge === "Poor" && <i class="fas fa-check"></i>}
                    </td>
                    <td className="text-center">
                      {formData.clinical_knowledge === "Fair" && <i class="fas fa-check"></i>}
                    </td>
                    <td className="text-center">
                      {formData.clinical_knowledge === "Average" && <i class="fas fa-check"></i>}
                    </td>
                    <td className="text-center">
                      {formData.clinical_knowledge === "Good" && <i class="fas fa-check"></i>}
                    </td>
                    <td className="text-center">
                      {formData.clinical_knowledge === "Excellent" && <i class="fas fa-check"></i>}
                    </td>
                  </tr>
                  <tr>
                    <th>3. Attendance/Dependability</th>
                    <td className="text-center">
                      {formData.attendance_dependability === "Poor" && <i class="fas fa-check"></i>}
                    </td>
                    <td className="text-center">
                      {formData.attendance_dependability === "Fair" && <i class="fas fa-check"></i>}
                    </td>
                    <td className="text-center">
                      {formData.attendance_dependability === "Average" && (
                        <i class="fas fa-check"></i>
                      )}
                    </td>
                    <td className="text-center">
                      {formData.attendance_dependability === "Good" && <i class="fas fa-check"></i>}
                    </td>
                    <td className="text-center">
                      {formData.attendance_dependability === "Excellent" && (
                        <i class="fas fa-check"></i>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th>4. Interpersonal/Communication Skills</th>
                    <td className="text-center">
                      {formData.communication_skills === "Poor" && <i class="fas fa-check"></i>}
                    </td>
                    <td className="text-center">
                      {formData.communication_skills === "Fair" && <i class="fas fa-check"></i>}
                    </td>
                    <td className="text-center">
                      {formData.communication_skills === "Average" && <i class="fas fa-check"></i>}
                    </td>
                    <td className="text-center">
                      {formData.communication_skills === "Good" && <i class="fas fa-check"></i>}
                    </td>
                    <td className="text-center">
                      {formData.communication_skills === "Excellent" && (
                        <i class="fas fa-check"></i>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th>5. Relationship with Others</th>
                    <td className="text-center">
                      {formData.relationship_others === "Poor" && <i class="fas fa-check"></i>}
                    </td>
                    <td className="text-center">
                      {formData.relationship_others === "Fair" && <i class="fas fa-check"></i>}
                    </td>
                    <td className="text-center">
                      {formData.relationship_others === "Average" && <i class="fas fa-check"></i>}
                    </td>
                    <td className="text-center">
                      {formData.relationship_others === "Good" && <i class="fas fa-check"></i>}
                    </td>
                    <td className="text-center">
                      {formData.relationship_others === "Excellent" && <i class="fas fa-check"></i>}
                    </td>
                  </tr>
                  <tr>
                    <th>6. Acceptance of Supervision</th>
                    <td className="text-center">
                      {formData.acceptance_supervision === "Poor" && <i class="fas fa-check"></i>}
                    </td>
                    <td className="text-center">
                      {formData.acceptance_supervision === "Fair" && <i class="fas fa-check"></i>}
                    </td>
                    <td className="text-center">
                      {formData.acceptance_supervision === "Average" && (
                        <i class="fas fa-check"></i>
                      )}
                    </td>
                    <td className="text-center">
                      {formData.acceptance_supervision === "Good" && <i class="fas fa-check"></i>}
                    </td>
                    <td className="text-center">
                      {formData.acceptance_supervision === "Excellent" && (
                        <i class="fas fa-check"></i>
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="col-md-12">
              <div className="mb-2">
                <label className="form-label fw-bold">Additional Comments</label>
                <p>{formData.additional_comments || "-"}</p>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn btn-secondary" onClick={toggleModal}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ReferenceForm;
